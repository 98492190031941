<template>
  <validation-observer ref="validationObserver">
    <b-card-form>
      <div>
        <div>
          <b-form
            ref="form"
            :style="{height: trHeight}"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >

            <!-- Row Loop -->
            <!-- draggable -->
            <draggable
              :list="items"
              tag="ul"
              group="items"
              class="list-group list-group-flush cursor-move"
            >
              <b-row
                v-for="(item, index) in items"
                :id="item.id"
                :key="item.id"
                ref="row"
              >
                <b-col
                  cols="12"
                >
                  <b-row>
                    <!-- Item Name -->
                    <b-col
                      md="4"
                      xl="5"
                    >
                      <b-form-group
                        label="Question English"
                        label-for="item-name"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :vid="'questionEn' + item.id"
                          rules="required"
                          name="Question English"
                        >
                          <b-form-input
                            id="item-name"
                            v-model="item.questionEn"
                            type="text"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Question English"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                      </b-form-group>
                    </b-col>

                    <!-- Item Name -->
                    <b-col
                      md="4"
                      xl="5"
                      dir="rtl"
                    >
                      <b-form-group
                        label="Question Arabic"
                        label-for="item-name"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :vid="'questionAr' + item.id"
                          rules="required"
                          name="Question Arabic"
                        >
                          <b-form-input
                            id="item-name"
                            v-model="item.questionAr"
                            type="text"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Question Arabic"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                      </b-form-group>
                    </b-col>

                    <!-- Remove Button -->
                    <b-col
                      lg="2"
                      md="3"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeItem(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>Delete</span>
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- Item Name -->
                    <b-col
                      md="4"
                      xl="5"
                    >
                      <label for="textarea-default">Answer English</label>
                      <validation-provider
                        #default="{ errors }"
                        :vid="'answerEn' + item.id"
                        rules="required"
                        name="Answer English"
                      >
                        <b-form-textarea
                          id="textarea-default"
                          v-model="item.answerEn"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Answer English"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-col>

                    <!-- Item Name -->
                    <b-col
                      md="4"
                      xl="5"
                      dir="rtl"
                    >
                      <label for="textarea-default">Answer Arabic</label>
                      <validation-provider
                        #default="{ errors }"
                        :vid="'answerAr' + item.id"
                        rules="required"
                        name="Answer Arabic"
                      >
                        <b-form-textarea
                          id="textarea-default"
                          v-model="item.answerAr"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Answer Arabic"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-col>

                  </b-row>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </draggable></b-form>
        </div>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeateAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add New</span>
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="save(false)"
            >
              <span>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card-form>
  </validation-observer>
</template>

<script>
import BCardForm from '@/components/b-card-form'
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormTextarea,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import UUID from '@/libs/uuid'
import draggable from 'vuedraggable'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCardForm,
    draggable,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [],
      nextTodoId: 2,
      required,
    }
  },
  mounted() {
    this.$http.get('/FAQ/faqs')
      .then(response => {
        this.items = response.data
      })
  },

  methods: {
    repeateAgain() {
      this.items.push({
        id: UUID(),
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    save(isPublish){
      const valid = this.$refs.validationObserver.validate().then(success => {
        if (success) {
          const data = { faqs: this.items }
          this.$http.post('/FAQ/faqs', data).then(()=>{
                if(!isPublish)
                {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Save Successfully",
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    },
                  });
                }
              })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Check the feilds validations above to be able to save!',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
        return success
      })
      return valid
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
