var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validationObserver"},[_c('b-card-form',[_c('div',[_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({height: _vm.trHeight}),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain.apply(null, arguments)}}},[_c('draggable',{staticClass:"list-group list-group-flush cursor-move",attrs:{"list":_vm.items,"tag":"ul","group":"items"}},_vm._l((_vm.items),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('b-form-group',{attrs:{"label":"Question English","label-for":"item-name"}},[_c('validation-provider',{attrs:{"vid":'questionEn' + item.id,"rules":"required","name":"Question English"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"item-name","type":"text","state":errors.length > 0 ? false:null,"placeholder":"Question English"},model:{value:(item.questionEn),callback:function ($$v) {_vm.$set(item, "questionEn", $$v)},expression:"item.questionEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"5","dir":"rtl"}},[_c('b-form-group',{attrs:{"label":"Question Arabic","label-for":"item-name"}},[_c('validation-provider',{attrs:{"vid":'questionAr' + item.id,"rules":"required","name":"Question Arabic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"item-name","type":"text","state":errors.length > 0 ? false:null,"placeholder":"Question Arabic"},model:{value:(item.questionAr),callback:function ($$v) {_vm.$set(item, "questionAr", $$v)},expression:"item.questionAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"2","md":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Delete")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Answer English")]),_c('validation-provider',{attrs:{"vid":'answerEn' + item.id,"rules":"required","name":"Answer English"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","state":errors.length > 0 ? false:null,"placeholder":"Answer English","rows":"3"},model:{value:(item.answerEn),callback:function ($$v) {_vm.$set(item, "answerEn", $$v)},expression:"item.answerEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4","xl":"5","dir":"rtl"}},[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Answer Arabic")]),_c('validation-provider',{attrs:{"vid":'answerAr' + item.id,"rules":"required","name":"Answer Arabic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","state":errors.length > 0 ? false:null,"placeholder":"Answer Arabic","rows":"3"},model:{value:(item.answerAr),callback:function ($$v) {_vm.$set(item, "answerAr", $$v)},expression:"item.answerAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add New")])],1)],1),_c('b-col',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save(false)}}},[_c('span',[_vm._v("Save")])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }